import { grey, indigo } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LayoutWrapper from './LayoutWrapper';

import './main.css';

const theme = createTheme({
  palette: {
    primary: {
      main: grey[100],
    },
    secondary: {
      main: indigo[600],
    }
  }
})

function App() {
  return (
    <ThemeProvider theme={theme}>
      <LayoutWrapper />
    </ThemeProvider>
  );
}

export default App;
