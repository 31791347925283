export const DATA = [
    {
        thumbnail: 'https://imgur.com/BZAbxPw.png',
        title: 'Air Quality Visual Analytic System',
        contact: 'Yun-Hsin Kuo',
        contactEmail: 'yskuo@ucdavis.edu',
        VIDIContributors: ['Yun-Hsin Kuo', 'Takanori Fujiwara'],
        otherContributors: ['Charles C.-K. Chou, Chun-houh Chen'],
        relevantPapers: [
            {
                title: 'A Machine-Learning-Aided Visual Analysis Workflow for Investigating Air Pollution Data',
                authors: 'Yun-Hsin Kuo, Takanori Fujiwara, Charles C.-K. Chou, Chun-houh Chen, and Kwan-Liu Ma',
                author: 'Kuo et al.', 
                publication: 'Pacific Visualization Symposium(PacificVis)',
                year: '2022',
                link: 'https://ieeexplore.ieee.org/abstract/document/9787881/',
                highlight: '', // honorable mention etc.
            },
        ],
        relevantCode: [],
        relevantDatasets: [],
        acknowledgements: 'This research was supported in part by the U.S. National Science Foundation through grant IIS-1741536 and a gift grant from Bosch Research.',
        description: 'A visual analytics application designed to support air pollution data analysis in temporal and spatial domains as well as the feature space.',
        localRef: true, //false for external URL 
        ref: 'airQuality',
        video: 'https://youtu.be/-FrqutJMEbE',
    },{
        thumbnail: 'https://imgur.com/XYeYGF7.png',
        title: 'Visual Analytics System Demo for Animal Infectious Diseases',
        contact: 'Yun-Hsin Kuo',
        contactEmail: 'yskuo@ucdavis.edu',
        VIDIContributors: ['Yun-Hsin Kuo'],
        otherContributors: ['Beatriz Martínez-López'],
        relevantPapers: [
            {
                title: 'Investigating Animal Infectious Diseases with Visual Analytics',
                authors: 'Yun-Hsin Kuo, Beatriz Martínez-López, and Kwan-Liu Ma',
                author: 'Kuo et al.', 
                publication: 'To appear in Pacific Visualization Symposium(PacificVis) 2023',
                year: '2023',
                link: '',
                highlight: '', // honorable mention etc.
            },
        ],
        relevantCode: [],
        relevantDatasets: [],
        acknowledgements: 'This research was supported by the U.S. National Science Foundation Awards No. 2134901 and 1838207. Authors would like to thank swine industry collaborators for the provision of high-quality data and their suggestions/insights to improve our outcomes.',
        description: 'A visual analytics application designed to support animal disease outbreak investigations, facilitated by the introduced health status inferences.',
        localRef: false, //false for external URL 
        ref: 'http://infovis.cs.ucdavis.edu:3039',
        video: '',
    },
    {
        thumbnail: 'https://imgur.com/2If6PE6.png',
        title: 'Synteny Explorer',
        contact: 'Keshav Dasu',
        contactEmail: 'kdasu@ucdavis.edu',
        VIDIContributors: ['Chris Bryan', 'Gregory Guterman', 'Ruoyang(Kathy) Liu'],
        otherContributors: ['Harris Lewin', 'Denis Larkin', 'Jaebum Kim', 'Jian Ma',  'Marta Farré'],
        relevantPapers: [
            {
                title: 'Synteny Explorer: An Interactive Visualization Application for Teaching Genome Evolution',
                authors: 'Chris Bryan, Gregory Guterman, Kwan-Liu Ma, Harris Lewin, Denis Larkin, Jaebum Kim, Jian Ma,  Marta Farré',
                author: 'Bryan et al.',
                publication: 'Transaction on Visualization and Computer Graphics(TVCG)',
                year: '2016',
                link: 'https://ieeexplore.ieee.org/abstract/document/7539391/',
                highlight: '',
            },
        ],
        relevantCode: [],
        relevantDatasets: [],
        acknowledgements: 'The authors wish to thank Francesca Samsel of the Center for Agile Technology, University of Texas at Austin, for assistance with color palette choices. This research was sponsored in part by the UC Davis RISE program, US National Science Foundation via grants DRL-1323214, IIS-1528203, and IIS-1320229, and U.S. Department of Energy via grant DE-FC02-12ER26072.',
        description: 'Rapid advances in biology demand new tools for more active research dissemination and engaged teaching. This project presents Synteny Explorer, based on a collaboration with the UC Davis genomics department. It is a new interactive visualization application designed for introductory teaching of the genomic rearrangments that happen during species evolution.',
        localRef: true, //false for external URL 
        ref: 'syntenyExplorer',
        video: 'https://youtu.be/W2-Rdgy55ak',
    },
    {
        thumbnail: 'https://i.imgur.com/iccnHoR.png',
        title: 'Scholar Tree',
        contact: 'David Bauer',
        contactEmail: 'dbauer@ucdavis.edu',
        VIDIContributors: ['Tsai-Ling Fung', 'Jia-Kai Chou', 'Kwan-Liu Ma'],
        otherContributors: [],
        relevantPapers: [
            {
                title: 'A Design Study of Personal Bibliographic Data Visualization',
                authors: 'Tsai-Ling Fung, Jia-Kai Chou, Kwan-Liu Ma',
                author: 'Fung et al.',
                publication: 'Pacific Visualization Symposium(PacificVis)',
                year: '2016',
                link: 'https://ieeexplore.ieee.org/document/7465279/',
                highlight: '',
            }
        ],
        relevantCode: [],
        relevantDatasets: [],
        acknowledgements: 'This work has been sponsored in part by U.S. Department of Energy through grant DE-SC0007443, U.S. National Science Foundation through grants IIS-1528203, IIS-1320229, and DRL-1323214, and UC Davis RISE program.',
        description: 'Scholar Tree characterizes one\'s research career in terms of his/her publication record listed in DBLP.',
        localRef: true, //false for external URL 
        ref: 'scholarTree',
        video: '',
    },
]

/* 
    {
        thumbnail: 'https://i.imgur.com/iccnHoR.png',
        title: 'Scholar Tree',
        contact: 'David Bauer',
        contactEmail: 'dbauer@ucdavis.edu',
        VIDIContributors: ['Tsai-Ling Fung', 'Jia-Kai Chou', 'Kwan-Liu Ma'],
        otherContributors: [],
        relevantPapers: [],
        relevantCode: [],
        relevantDatasets: [],
        acknowledgements: '',
        description: '',
        localRef: true, //false for external URL 
        ref: 'webglVolvis',
    },
*/

