import React from 'react'
import { styled } from '@mui/material/styles';
import { AppBar, Grid, Container, Toolbar, Typography, Box, Card, CardMedia, CardContent, Divider, Link, Button, Stack } from '@mui/material';
import { DATA } from './data';
import { formatEmail, formatRef } from './helpers';
import logo from './vidi2013_logo_black.png';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const AppContainer = styled(Grid)(({ theme }) => ({
    margin: '0px 0px',
    padding: '5px 5px',
    height: '100%',
    width: '100%',
    flexGrow: 1,
    alignItems: 'stretch',
    flexWrap: 'nowrap',
}))

function Bar() {
    return (
        <AppBar position='static'>
            <Container maxWidth='xl'>
                <Toolbar disableGutters>
                    <img src={logo} alt='VIDILogo' width='70'></img>
                    <Typography variant='h6' sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' }, marginLeft: '1rem' }}>VIDI Products</Typography>
                </Toolbar>
            </Container>
        </AppBar>
    )
}

//TODO: make each entry a separated component
function Product({ data }) {
    return (
        <Card sx={{ display: 'flex', padding: '.5rem', flexDirection: 'column' }} elevation={5}>
            <Box sx={{ display: 'flex', flexDirection: 'row'}}>
                <Typography variant='overline' sx={{fontWeight: 'bold', fontSize: '1rem', padding: '0px 16px'}}>
                    {data.title}
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row'}}>
                <CardContent sx={{width: '50%'}}>
                    <Typography variant='body1' sx={{paddingBottom: '1rem'}}>
                        {(data.description) ? data.description : 'To be added soon!'}
                    </Typography>
                </CardContent>
                <CardMedia
                    component='img'
                    sx={{ width: '50%', paddingTop: '16px', lineHeight: 1.5, paddingRight: '16px'}}
                    image={data.thumbnail} //'https://i.imgur.com/6Kf0RJY.jpeg'
                    alt={data.ref}
                />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column'}}>
                <CardContent>
                    <Stack direction='row' spacing={1}>
                        <Button variant='contained' size='sm' color='secondary' href={(data.localRef) ? formatRef(data.ref) : data.ref}>Demo</Button>
                        {(data.video) ? (<Button variant='contained' size='sm' color='secondary' href={data.video}>Video</Button>) : <></>}
                    </Stack>
                </CardContent>
                {(data.relevantPapers) ? (<>
                    <Divider variant='middle' />
                    <CardContent>
                        <Typography component='div' variant='caption' sx={{ fontWeight: 'bold' }}>Relevant papers</Typography>
                        {(data.relevantPapers.map((d, idx) => (<>
                            <Link key={idx} href={d.link} variant='caption' className='paper-links'>{`${d.title} (${d.author}, ${d.year})`}
                            <OpenInNewIcon key={idx} sx={{ fontSize: '0.75rem', lineHeight: 1.66}}/>
                            </Link>
                        </>)))}
                    </CardContent>
                </>) : <></>}
                {(data.acknowledgements) ? (<>
                    <Divider variant='middle' />
                    <CardContent>
                        <Typography component='div' variant='caption' sx={{ fontWeight: 'bold' }}>Acknowledgements</Typography>
                        <Typography variant='caption'>
                            {data.acknowledgements}
                        </Typography>                 
                    </CardContent>
                </>): <></>}
                <Divider variant='middle'/>
                <CardContent>
                    <Typography component='div' variant='caption' color='gray'>{`VIDI Contributors: ${data.VIDIContributors.join(', ')}`}</Typography>
                    {(data.otherContributors.length !== 0) ? (<Typography component='div' variant='caption' color='gray'>{`Other Contributors: ${data.otherContributors.join(', ')}`}</Typography>) : <></>}
                    <Typography component='div' variant='caption' color='gray'>{`Contact: ${data.contact} (${formatEmail(data.contactEmail)})`}</Typography>
                </CardContent>
            </Box>
        </Card>
    )
}

export default function LayoutWrapper() {
    return (
    <>
        <Bar />
        <AppContainer container columnSpacing={1} rowSpacing={1}>
                <Box sx={{
                    p: 5, display: 'grid', width: '100%', height: '100%',
                    gridTemplateColumns: { sm: '1fr', md: '1fr 1fr' }, gap: 3, overflow: 'auto', position: 'relative'
                }}>
                    {DATA.map((entry, idx) => (<Product key={idx} data={entry} />))}
            </Box>
        </AppContainer>
    </>

  )
}
